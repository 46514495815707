import containerQueries from '@tailwindcss/container-queries';
import type { Config } from 'tailwindcss';

// eslint-disable-next-line max-len
// https://app.abstract.com/projects/01a6c731-be66-4a54-bb62-5b21312da463/branches/main/commits/9af04eefe5acc4ec14f5a27a18b5efbed22643d2/files/8b8da143-4c21-4a62-a7ab-58e2bf25a360/layers/27A670A1-D17F-4070-9D56-0FBE7B258BA7
const screens = {
    /* eslint-disable sort-keys-fix/sort-keys-fix */
    sm: '576px',
    md: '768px',
    lg: '1024px',
    xl: '1440px',
    '2xl': '1920px',
    /* eslint-enable sort-keys-fix/sort-keys-fix */
};

const b2bGlobalStyles = {
    borderRadius: {
        DEFAULT: 'var(--border-radius-standard)',
        full: 'var(--border-radius-full)',
        md: 'var(--border-radius-medium)',
        none: '0',
    },
    boxShadow: {
        DEFAULT: '0px 0px 15px 0px rgba(0, 0, 0, 0.1)',
        bottom: '0px 15px 10px -15px rgba(0,0,0,0.2)',
        hover: '0px 0px 10px 0px rgba(20, 37, 66, 0.2)',
        lg: '1px 1px 10px 0 rgba(20, 37, 66, 0.3)',
        md: '0px 0px 15px 0px rgba(0, 0, 0, 0.2)',
        none: 'none',
        outline: 'inset currentColor 0 0 0 1px', // box-shadow instead of border => no padding/margin issues
        top: '0 -5px 15px -5px rgba(0,0,0,0.2)',
    },
    colors: {
        black: '#000',
        blue: {
            50: '#e6eefa',
            100: '#cdddf5',
            200: '#b3cdf0',
            300: '#81abe6',
            400: '#689ae1',
            500: '#3579d7',
            600: '#0357cd',
            700: '#0246a4',
            800: '#02347b',
            900: '#012352',
        },
        gray: {
            50: '#f5f7f7',
            100: '#edeff1',
            200: '#e3e6e9',
            300: '#c9cdd4',
            400: '#a2aab6',
            500: '#7c8797',
            600: '#59667b',
            700: '#435269',
            800: '#2e3d56',
            900: '#142542',
        },
        green: {
            50: '#f4fbf0',
            100: '#e9f7de',
            200: '#daefc0',
            300: '#c6e69f',
            400: '#b4dd7f',
            500: '#a4d560',
            600: '#8bc72a',
            700: '#619f00',
            800: '#377800',
            900: '#0d5300',
        },
        red: {
            50: '#faeeed',
            100: '#f7e0de',
            500: '#cd463a',
            700: '#a6201e',
            800: '#750000',
        },
        transparent: 'transparent',
        white: '#fff',
        yellow: {
            50: '#fef9ea',
            100: '#fef5d9',
            600: '#f7c01b',
            800: '#c29305',
            900: '#9f7700',
        },
    },
    container: {
        padding: {
            DEFAULT: 'var(--container-px)',
        },
    },
    containers: screens,
    extend: {
        animation: {
            loading: 'loading 1s infinite ease-out',
        },
        keyframes: {
            loading: {
                '0%, 100%': {
                    opacity: '0.25',
                },
                '20%': {
                    opacity: '1',
                },
            },
        },
        maxWidth: {
            'container-slim': 'calc(736px + 2 * var(--container-px))',
            content: '1600px',
        },
    },
    fontFamily: {
        sans: ['Open Sans', 'sans-serif'],
    },
    fontSize: {
        /* eslint-disable sort-keys-fix/sort-keys-fix */
        xs: ['0.75rem', '1'] /** h:12px,lh:12px */,
        sm: ['0.875rem', '1.5'] /** h:14px,lh:21px */,
        base: ['1rem', '1.5'] /** h:16px,lh:24 - medium */,
        lg: ['1.125rem', '1.4'] /** h:18px,lh:25.2px */,
        xl: ['1.5rem', '1.3'] /** h:24px, lh:31.2px */,
        '2xl': ['2rem', '1.3'] /** h:32px, lh:41.6px */,
        '3xl': ['2.5rem', '1.2'] /** h:40px, lh:48px */,
        '4xl': ['3rem', '1.2'] /** h:48px, lh:57.6px */,
        '5xl': ['4rem', '1.1'] /** h:64px, lh:70.4 */,
        /* eslint-enable sort-keys-fix/sort-keys-fix */
    },
    fontWeight: {
        regular: '400',
        semibold: '600',
    },
    outlineColor: {
        blue: {
            600: '#0357cd',
        },
        current: 'currentColor',
        gray: {
            900: '#142542',
        },
        yellow: {
            600: '#f7c01b',
        },
    },
    screens: {
        ...screens,
        print: {
            raw: 'print',
        },
    },
    spacing: {
        '0': '0',
        '1': '0.125rem',
        '2': '0.25rem',
        '3': '0.5rem',
        '4': '0.75rem',
        '5': '1rem',
        '6': '1.5rem',
        '7': '2rem',
        '8': '2.5rem',
        '9': '3rem',
        '10': '3.5rem',
        '11': '4rem',
        '12': '4.5rem',
        '13': '5rem',
        '14': '6rem',
        '16': '8rem',
    },
};
export default <Partial<Config>>{
    content: ['./packages/**/*.{vue,ts}', '!./packages/**/node_modules/*'],
    corePlugins: {
        backgroundColor: true,
        borderColor: true,
        borderRadius: true,
        display: true,
        flex: true,
        flexGrow: true,
        flexShrink: true,
        fontWeight: true,
        gap: true,
        gridTemplateColumns: true,
        margin: true,
        padding: true,
        preflight: true,
        textColor: true,
        textTransform: true,
        verticalAlign: true,
        width: true,
    },
    plugins: [containerQueries],
    theme: {
        ...b2bGlobalStyles,
    },
};
